<template>
  <div class="login-container">
    <img src="../../../assets/login_logo.png" style="width: 200px;height: auto;margin-top: 70px">
    <p class="login-hint">请输入手机号后验证登录</p>
    <el-input v-model="user_mobile" type="number" placeholder="请输入手机号码" style="margin-top: 25px"/>
    <div style="margin-top: 20px;position: relative;width: 100%;height: 42px">
      <div style="position:absolute;width: 100%">
        <el-input v-model="code" type="number" placeholder="请输入手机号码"/>
      </div>
      <p class="login-code" @click="getCode">{{ codeInfo }}</p>
    </div>
    <p class="btn-login" @click="doLogin">登录</p>
  </div>
</template>

<script>
import {createLoginPin, requestWXUserInfo, verifyLoginPin} from "../../../api/keneng";
import {
  getOpenId,
  getUnionId,
  getUserId,
  setOauthUrl,
  setOpenId,
  setUnionId,
  setUserId,
  setUserMobile
} from "../../../utils/store";

export default {
  name: "Login",
  data() {
    return {
      wx_code: '',
      TOTAL_SECONDS: 60,
      user_mobile: "",
      code: '',
      codeInfo: '获取验证码',
      countDown: undefined,
      enableLogin: false
    }
  },
  beforeDestroy() {
    if (this.countDown) {
      window.clearInterval(this.countDown)
    }
  },
  created() {
    if (this.$route.query && this.$route.query.code) {
      this.wx_code = this.$route.query.code
      let link = window.location.href
      setOauthUrl(link)
    }
    let user_id = getUserId()
    let union_id = getUnionId()

    if(false){
      user_id = "5ee9b932a4640bdc378b456b"
      union_id = "oD3ql0eleM5rtFg55oM0rHvZ8pQM"
      setUnionId(union_id)
      setUserId(user_id)
    }

    if (user_id && user_id != '' && union_id && union_id != '') {
      this.$router.replace({
        path: '/home'
      })
      return
    }
    if (this.wx_code) {
      //获取信息
      requestWXUserInfo(this.wx_code).then((res) => {
        if (res.data.code === 0) {
          var result = res.data.data
          if (result.unionid) {
            this.enableLogin = true
            var unionid = result.unionid
            let open_id = result.openid
            setUnionId(unionid)
            setOpenId(open_id)
          } else {
            this.$message.error('发现你还没有关注我们的公众号，请先关注公众号！')
          }
        } else {
          this.$message.error('获取用户信息出错')
        }
      })
    } else {
      this.$message.error('未获取到code授权')
    }
  },
  methods: {

    getCode() {

      if (!this.enableLogin) {
        this.$message.error('获取用户信息出错～')
        return;
      }

      if (this.user_mobile == '') {
        this.$message.warning('请输入手机号码~')
        return
      }
      if (false) {
        this.startCodeCountDown()
        return;
      }
      createLoginPin(this.user_mobile).then((res) => {
        let result = res.data
        if (result.Code && result.Code == 'OK') {
          this.$message.success('验证码发送成功')
          //开始倒计时
          this.startCodeCountDown()
        } else {
          this.$message.error('发送过于频繁，请稍后重试！')
        }
      })
    },
    startCodeCountDown() {
      var totalSeconds = this.TOTAL_SECONDS + 1
      this.countDown = setInterval(() => {
        totalSeconds--
        if (totalSeconds <= 0) {
          clearInterval(this.countDown)
          this.codeInfo = '获取验证码'
        } else {
          this.codeInfo = '倒计时' + totalSeconds + '秒'
        }
      }, 1 * 1000)
    },
    doLogin() {
      if (!this.enableLogin) {
        this.$message.error('获取用户信息出错～')
        return;
      }
      if (this.user_mobile == '') {
        this.$message.error('请输入手机号码~')
        return
      }
      if (this.code == '') {
        this.$message.error('请输入短信验证码~')
        return
      }
      let unionid = getUnionId()
      let open_id = getOpenId()
      verifyLoginPin(this.user_mobile, this.code, unionid, open_id).then((res) => {
        let result = res.data
        if (result.code == 0) {
          const user_id = result.data._id.$id
          setUserId(user_id)
          setUserMobile(this.user_mobile)
          window.location.href = 'https://login.kenengxueyuan.com/scan'
          // this.$router.push('/scan')
          this.$message.success('登录成功～')
        } else {
          this.$message.error(result.msg)
        }
      })
    }
  }
}
</script>

<style scoped>

.btn-login {
  text-align: center;
  width: 100%;
  margin-top: 40px;
  cursor: pointer;
  height: 42px;
  line-height: 42px;
  font-size: 18px;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #FFFFFF;
  background: #5D5BB5;
  box-shadow: 0px 0px 24px 1px rgba(0, 0, 0, 0.05);
  border-radius: 26px 26px 26px 26px;
}

.login-container {
  position: relative;
  background-image: url("../../../assets/login_bg.png");
  background-size: cover;
  background-color: transparent;
  background-repeat: no-repeat;
  width: 100vw;
  height: 100vh;
  padding: 30px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-hint {
  margin-top: 25px;
  font-size: 15px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #666666;
}

/deep/ .el-input__inner {
  border-radius: 25px;
  border-width: 0px;
  height: 42px;
}

.login-code {
  right: 28px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 999;
  position: absolute;
  font-size: 14px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #5D5BB5;
}


</style>
